var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header--image",attrs:{"data-aos":"fade-in","data-overlay":""}},[(_vm.bannerSettings.imageUrl)?_c('div',{staticClass:"imagebg",style:(`background-image: url(${_vm.bannerSettings.imageUrl})`)}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"sectiontitle-element--center"},[(_vm.bannerSettings.title)?_c('h1',{staticClass:"banner-title",attrs:{"data-aos":"fade-in"}},[_vm._v(" "+_vm._s(_vm.bannerSettings.title)+" ")]):_vm._e(),(_vm.bannerSettings.description)?_c('p',{staticClass:"banner-description",attrs:{"data-aos":"fade-in","data-aos-delay":"200"}},[_vm._v(" "+_vm._s(_vm.bannerSettings.description)+" ")]):_vm._e(),(
                            _vm.bannerSettings.buttonUrl &&
                            _vm.bannerSettings.title.toLowerCase() !==
                                'cashwyre for business'
                        )?_c('a',{staticClass:"btn--big--round",attrs:{"href":"#download-app"}},[_vm._v(" "+_vm._s(_vm.bannerSettings.buttonUrl)+" ")]):_vm._e(),_c('div',{staticClass:"cta_link"},[(
                                _vm.bannerSettings.title.toLowerCase() ===
                                'cashwyre for business'
                            )?_c('a',{staticClass:"btn--big--border--light--round",attrs:{"href":"https://business.cashwyre.com/signup","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" Get started ")]):_vm._e()])]),_c('div',{staticClass:"list-element--text--breadcrumbs--arrow--light"},[_c('ul',[(_vm.bannerSettings.prevLinkText)?_c('li',[_c('router-link',{attrs:{"to":{ name: _vm.bannerSettings.prevLinkText }}},[_vm._v(_vm._s(_vm.bannerSettings.prevLinkText))])],1):_vm._e(),(_vm.bannerSettings.currentLinkText)?_c('li',[_vm._v(" "+_vm._s(_vm.bannerSettings.currentLinkText)+" ")]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }